import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import Hero from "../../components/Hero"
import Introduction from "../../components/Introduction"
import CurrentJobOpenings from "../../sections/careers/CurrentJobOpenings"

import { jobList } from "../../sections/careers/data"
import careersHeroBgUrl from "../../../media/careers-banner-overlay.png"

const careersHeroTitle = "Careers"
const careersHeroDescription = ""
const introductionTitle = ""
const introductionPara1 = "At Grandview, our team of financial services industry experts approaches every client challenge as their own. Whether configuring a portfolio management solution or integrating data across systems, we are driven by a common goal to be the best in the world at helping financial institutions manage their business systems and data assets."
const introductionPara2 = "If you love solving complex data and technology problems with creativity, drive, and determination, then Grandview may be the place for you. If you appreciate variety in your work and collaboration with client stakeholders to deliver solutions that makes their work easier, faster, and more effective, then we want to talk to you."
const introductionPara3 = "At Grandview, the limits are only bound by the imagination and drive of each employee. We encourage a growth mindset where there is room for fresh thinking and grand ideas. Grand careers start at Grandview!"
const para = [
  introductionPara1,
  introductionPara2,
  introductionPara3
]

const CareersPage = ({ location }: PageProps) => (
  <Layout location={location}>
    <SEO title="Careers" />
    <Hero
      backgroundImage={careersHeroBgUrl}
      title={careersHeroTitle}
      description={careersHeroDescription}
    />
    <Introduction
      title={introductionTitle}
      introduction={para}
    />
    <CurrentJobOpenings list={jobList} />
  </Layout>
)

export default CareersPage
