import * as React from "react"
import { navigate } from "@reach/router"
import { Typography, } from "@material-ui/core"

import { useStyles } from "./styles"

const title = "Current Job Openings"
const description = "Grandview is currently hiring for the following positions. To submit your resume, please send an email to careers@grandviewanalytics.com and let us know for which position you'd like to be considered."

interface CurrentJobOpeningsProps {
  list: Array<Object>
}

const CurrentJobOpenings = ({ list }: CurrentJobOpeningsProps) => {
  const classes = useStyles()


  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body2" className={classes.description}>
          {description}
        </Typography>
        <div className={classes.listWrapper}>
        {list.map((item: any, index) => (
          <div
            key={`job-opening-item-${index}`}
            className={`${classes.item} ${index % 2 === 0 ? 'even' : 'odd'}`}
            onClick={() => navigate(item.link, { state: { jobId: index } })}
          >
            <Typography variant="body1" className={classes.itemTitle}>
              {item.title}
            </Typography>
          </div>
        ))}
        </div>
      </div>
    </div>
  )
}

export default CurrentJobOpenings
