import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    padding: 60,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  innerWrapper: {
    width: "100%",
    maxWidth: 1440,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
  },
  description: {
    textAlign: "left",
    maxWidth: "1300px"
  },
  listWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 40,
    "& :hover":{
      cursor: "pointer"
    },
  },
  item: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    boxShadow: "none",
    borderRadius: "0 !important",
    padding: "20px 40px",
    "& :hover":{
      cursor: "pointer"
    },
    [theme.breakpoints.down("sm")]: {
      padding: 10,
      flexDirection: "column-reverse",
      "&.even": {
        background: "#e9eaec",    
      },
      "&.odd": {
      },
    },
    [theme.breakpoints.up("md")]: {
      padding: "20px 40px",
      "&.even": {
        flexDirection: "row",
        background: "#e9eaec",    
      },
      "&.odd": {
        flexDirection: "row-reverse",
      },
    },
  },
  itemTitle: {
    color: "#000",
    textAlign: "center",
  },
}))
