export const jobList = [
  {
    title: "Business / Data Analyst - Chicago, IL",
    link: "/careers/current-job-openings",
    description: "We are currently seeking to fill full-time senior analyst, VP, and director-level positions with candidates who love solving complex data and technology problems with creativity, drive, and determination. Hands-on experience working in the financial services industry in business analysis, data management, and reporting roles is required.",
    list: [
      {
        title: "Data Analysis Responsibilities",
        list: [
          "Analyze investment data to create data quality metrics and make operational / technology recommendations to improve the integrity of data",
          "Investigate and document root cause issues and effectively communicate underlying issues to project team",
          "Develop advanced cash flow, compliance, and risk models in MS Excel",
          "Develop portfolio, investor, and risk reports using Business Objects, Crystal Reports, or other preferred BI tools that are elected by our clients",
          "Lead and/or participate in database architecture and design discussions",
          "Integrate vendor and proprietary systems to create streamlined workflows"
        ]
      },
      {
        title: "Business Analysis Responsibilities",
        list: [
          "Lead client meetings to discuss project scope and gather business requirements.",
          "Liaise between business and technology personnel (both internal and vendor I.T.) to ensure business requirements are met",
          "Perform quality assurance and system testing to ensure expected results are achieved",
          "Organize and execute an overall project timeline with deliverables and assigned task owners",
          "Create policies, procedures, and current / future state workflow diagrams using Microsoft Word and Visio",
          "Proactively identify and recommend opportunities to improve operational efficiency for our clients",
          "Configure vendor systems to meet client requirements",
          "Perform gap analysis for vendor systems and recommend workaround solutions",
          "Create presentations using PowerPoint; assist in developing sales materials for new client prospects"
        ]
      },
      {
        title: "Qualifications",
        list: [
          "Must have prior experience in Investment Management, Banking, Fund Accounting, Private Equity or other Financial Services role",
          "Must have strong experience with investment data and investment operations (security master, trade processing, reconciliation, corporate actions, compliance)",
          "Must be able to work independently to research and solve complex problems from both business and technology perspective",
          "Must have an interest in entrepreneurship and contributing to the growth of a new business",
          "Must have degree in Accounting, Finance, or other business-related field; MBA and/or CFA are a plus, but not required",
          "Must have strong desire to work within a collaborative and high-paced work environment along with ability to work overtime on a regular basis",
          "Must be organized, motivated to meet strict deadlines, and always willing to go above and beyond for clients and colleagues"
        ]
      }
    ]
  }
]
